<template>
  <div>
    <pageBuilder :save="save" :editID="editID" :onClose="function () { onClose() }" v-if="edit === 1"/>
    <flixDashboard v-else>
      <template v-slot:content>
        <div class="flix-container" :key="updateSave">
          <div class="flix-row">
            <div class="flix-col-md-12">
              <div class="flix-form-group">
                <h3 class="flix-html-h3">{{ $t('message.edit', {name: $tc('message.targetPage', 1)}) }}</h3>
              </div>
              <div class="flix-form-group">
                <calendarBreadcrumbs :link="'dashboardSpecificTargetPage'" />
              </div>
            </div>
            <transition name="flixFadeIn">
              <div class="flix-col-md-12" :key="$route.params.id" v-if="$route.params.id !== 'all'">
                <div class="flix-form-group">
                  <addBtn v-if="!checkID($route.params.id)" :onClick="function () { addTargetPage() }">
                    <template v-slot:text>
                      {{ $t('message.add', {name: $tc('message.targetPage', 1)}) }}
                    </template>
                  </addBtn>
                  <span v-else>
                    <editBtn :onClick="function () { editTargetPage() }" v-if="editData.method !== 'customUrl'">
                      <template v-slot:text>
                        {{ $t('message.edit', {name: $tc('message.targetPage', 1)}) }}
                      </template>
                    </editBtn>
                    <editBtn :onClick="function () { editCustom = !editCustom }">
                      <template v-slot:text v-if="editData.method !== 'customUrl'">
                        {{ $t('message.add', {name: $tc('message.customUrl', 1)}) }}
                      </template>
                      <template v-slot:text v-else>
                        {{ $t('message.edit', {name: $tc('message.customUrl', 1)}) }}
                      </template>
                    </editBtn>
                  <a href="#" class="flix-pull-right flix-btn flix-btn-lg flix-btn-danger" @click.prevent="function () { onDelete() }">
                      <flixIcon id="bin" /> {{ $tc('message.targetPage', 1) }}
                  </a>
                  <a :href="url" target="_blank" class="flix-btn flix-btn-default"><flixIcon id="eye-open" /> {{ $t('message.preview') }}</a>
                  </span>
                </div>
              </div>
            </transition>
            <div class="flix-col-md-12" v-if="checkID($route.params.id) && editCustom">
              <customURL :edit="editData" :data="save" :variables="variables" :callback="setTargetPage" />
            </div>
            <div class="flix-col-md-12">
              <div class="flix-form-group">
                <span class="flix-html-info flix-text-info"><flixIcon id="info-sign" /> {{ $t('message.targetPageInfo') }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </flixDashboard>
  </div>
</template>
<script>
import { standardLayout } from '@/components/targetPage/standardLayout.js'
export default {
  components: {
    pageBuilder () { return import('@/components/targetPage/pageBuilder') },
    calendarBreadcrumbs () { return import('@/components/assets/calendarBreadcrumbs') },
    customURL () { return import('@/components/targetPage/customURL') }
  },
  props: {},
  mounted () {
    this.getSave()
    this.getUrl()
  },
  computed: {

  },
  data () {
    return {
      edit: this.$route.params.edit,
      variables: this.$getUserVariables(),
      save: [],
      editID: false,
      updateSave: 0,
      url: false,
      editCustom: false,
      editData: false
    }
  },
  watch: {
    '$route.params.id' () { this.getSave(); this.getUrl() }
  },
  methods: {
    getEditData () {
      this.save.forEach(function (r) {
        if (r.ID === 'tp-' + this.$route.params.id) {
          this.editData = r
        }
      }.bind(this))
    },
    checkID (ID) {
      var r = false
      this.save.forEach(function (val) {
        if (val.ID === 'tp-' + ID) {
          r = true
        }
      })
      return r
    },
    onClose (ID) {
      if (this.edit * 1 === 0) {
        this.editID = 'tp-' + ID
        this.edit = 1
      } else {
        this.editID = false
        this.edit = 0
      }
    },
    onDelete () {
      var key = false
      var ID = 'tp-' + this.$route.params.id
      this.save.forEach(function (s, k) {
        if (s.ID === ID) {
          key = k
        }
      })
      this.$flix_post({
        url: 'pagebuilder/targetpage/delete',
        data: {
          user: this.variables.user.md5_id,
          ID: this.save[key].ID
        },
        callback: function (ret) {
          this.save.splice(key, 1)
          this.getSave()
          this.getUrl()
        }.bind(this)

      })
    },
    getUrl () {
      this.url = this.$flix_url + '/' + this.$i18n.locale + '/' + this.variables.user.username + '/tp-' + this.$route.params.id + '/index.html'
    },
    getSave () {
      this.$store.dispatch('getTargetPages', {
        user: this.variables.user.md5_id,
        callback: function (ret) {
          this.save = ret
          this.getEditData()
        }.bind(this)
      })
    },
    addTargetPage () {
      var data = {
        ID: 'tp-' + this.$route.params.id,
        assistents: [this.$route.params.id],
        method: 'targetPage',
        layout: standardLayout
      }
      this.$store.dispatch('setTargetPage', {
        user: this.variables.user.md5_id,
        ID: data.ID,
        data: data.layout,
        meta: { assistents: data.assistents, method: data.method },
        callback: function () { this.setTargetPage(data) }.bind(this)
      })
    },
    setTargetPage (data) {
      this.save.push(data)
      this.onChangeAssistents(data.ID)
      this.getSave()
      this.getUrl()
      this.getEditData()
    },
    editTargetPage () {
      window.location.hash = '/'
      this.editID = 'tp-' + this.$route.params.id
      this.edit = 1
    },
    onChangeAssistents (id) {
      var active
      this.save.forEach(function (v) {
        if (v.ID === id) {
          active = v
        }
      })

      this.save.forEach(function (v, k) {
        if (v.ID !== id) {
          active.assistents.forEach(function (a) {
            if (v.assistents.indexOf(a) !== -1) {
              this.save[k].assistents.splice(v.assistents.indexOf(a), 1)
              this.$store.dispatch('setTargetPage', {
                user: this.$getUserVariables().user.md5_id,
                ID: v.ID,
                data: v.layout,
                meta: { assistents: v.assistents, method: v.method },
                callback: function () { }
              })
            }
          }.bind(this))
        }
      }.bind(this))

      this.updateSave = new Date().getTime()
      // window.console.log(active)
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
